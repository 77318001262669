import Cookies from 'js-cookie';
import { FE_BASE_URL } from 'configs/apiUrl';

export const saveString = (key: string, value: string, age: number) => {
    let domain = new URL(FE_BASE_URL);
    try {
        console.log('cookie domian-nya ==>', domain.hostname);
        Cookies.set(key, value, {
            expires: Math.floor(age / (3600 * 24)),
            secure: true,
            domain: domain.hostname
        });
        return true;
    } catch (error) {
        return false;
    }
};

export const saveCookie = (key: string, value: string | object, age: number) => saveString(key, JSON.stringify(value), age);

export const getCookie = (key: string) => {
    try {
        const itemString = Cookies.get(key);
        if (itemString) {
            return JSON.parse(itemString);
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
};

export default {
    saveString,
    saveCookie,
    getCookie
};
