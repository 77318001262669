import { useRoutes } from 'react-router-dom';

// routes
import SettingRoutes from './SettingRoutes';
import AdminRoutes from './AdminRoutes';
import SalesRoutes from './SalesRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import LegalRoutes from './LegalRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AdminRoutes, AuthenticationRoutes, SettingRoutes, SalesRoutes, LegalRoutes]);
}
