import Loadable from 'ui-component/Loadable';

import { lazy } from 'react';

export const UserPage = Loadable(lazy(() => import('modules/user/pages/UserPage')));
export const AddUserPage = Loadable(lazy(() => import('modules/user/pages/AddUserPage')));
export const EditUserPage = Loadable(lazy(() => import('modules/user/pages/EditUserPage')));

// export const FileTemplatePage = Loadable(lazy(() => import('modules/file-template/pages/FileTemplatePage')));
export const InputBulkPage = Loadable(lazy(() => import('modules/input-bulk/InputBulkPage')));
export const FileStandardPage = Loadable(lazy(() => import('modules/file-standard/pages/FileStandardPage')));

export const ExcelDataPicPage = Loadable(lazy(() => import('modules/input-bulk/excel-data-pic/InputBulkPicPage')));
export const ExcelAgreementPage = Loadable(lazy(() => import('modules/input-bulk/excel-existing/InputBulkAgreementPage')));
