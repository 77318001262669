// project imports
import MainLayout from 'layout/MainLayout';
import {
    ClientPicPage,
    AddClientPicPage,
    EditClientPicPage,
    AgreementPage,
    DetailAgreementPage,
    AddAgreementPage,
    EditAgreementPage,
    ProjectExistingPage,
    AddProjectExistingPage,
    EditProjectExistingPage,
    AgreementProgressOutPage,
    ProcessOutPage
} from './Loadable/SalesRoutes';

// ==============================|| MAIN ROUTING ||============================== //
const DataAwalRoutes = {
    path: 'sales',
    element: <MainLayout />,
    children: [
        {
            path: 'client-pic',
            children: [
                {
                    path: '',
                    element: <ClientPicPage />
                },
                {
                    path: 'create',
                    element: <AddClientPicPage />
                },
                {
                    path: 'edit',
                    element: <EditClientPicPage />
                }
            ]
        },
        {
            path: 'project-existing',
            children: [
                {
                    path: '',
                    element: <ProjectExistingPage />
                },
                {
                    path: 'create',
                    element: <AddProjectExistingPage />
                },
                {
                    path: 'edit',
                    element: <EditProjectExistingPage />
                }
            ]
        },
        {
            path: 'agreement',
            children: [
                {
                    path: ':unitbispro',
                    element: <AgreementPage />
                },
                {
                    path: ':unitbispro/create',
                    element: <AddAgreementPage />
                },
                {
                    path: ':unitbispro/edit',
                    element: <EditAgreementPage />
                },
                {
                    path: ':unitbispro/detail/:id',
                    element: <DetailAgreementPage />
                }
            ]
        },
        {
            path: 'agreement-progress-out',
            children: [
                {
                    path: '',
                    element: <AgreementProgressOutPage />
                },
                {
                    path: 'detail/:id',
                    element: <ProcessOutPage />
                }
            ]
        }
    ]
};

export default DataAwalRoutes;
