import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { getCookie } from 'lib/helpers/cookie';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Check if the cookie exists
    // if (getCookie('SOS-PERJANJIAN-SECURE') != null) {
    //     navigate('/', {
    //         replace: true,
    //         state: location.pathname
    //     });
    //     return null;
    // }

    // Render Outlet and Customization components if cookie doesn't exist
    return (
        <>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default MinimalLayout;
