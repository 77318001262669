import dashboard from './dashboard';
import setting from './setting';
import sales from './sales';
import legal from './legal';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, setting, sales, legal]
};

export default menuItems;
