import type { BaseQueryFn } from '@reduxjs/toolkit/query';
// import axios from 'axios';
import { axios } from 'lib/utils/axios';
import { refreshTokenMemApi } from '../helpers/refreshTokenMemApi';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import { API_BASE_URL } from 'configs/apiUrl';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Mutex } from 'async-mutex';

interface BaseURLProps {
    baseUrl: string;
}

const mutex = new Mutex();
export const baseAxios =
    (
        { baseUrl }: BaseURLProps = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            headers?: any;
            responseType?: AxiosRequestConfig['responseType'];
            params?: AxiosRequestConfig['params'];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, headers, responseType, params }) => {
        await mutex.waitForUnlock();
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                headers,
                responseType,
                params
            });

            return { data: result.data };
        } catch (axiosError) {
            let err = axiosError as AxiosError;
            console.log('err.response =>', err.response?.status);
            if (err.response?.status === 401) {
                if (!mutex.isLocked()) {
                    const release = await mutex.acquire();
                    try {
                        console.log('err.response.try =>', err.response?.status);
                        await refreshTokenMemApi();
                        const result = await axios({
                            url: baseUrl + url,
                            method,
                            data,
                            headers,
                            responseType,
                            params
                        });
                        return { data: result.data };
                    } finally {
                        // release must be called once the mutex should be released again.
                        release();
                    }
                } else {
                    await mutex.waitForUnlock();
                    try {
                        const result = await axios({
                            url: baseUrl + url,
                            method,
                            data,
                            headers,
                            responseType,
                            params
                        });
                        return { data: result.data };
                    } catch (error) {
                        return {
                            error: {
                                status: err.response?.status,
                                data: err.response?.data || err.message
                            }
                        };
                    }
                }
            }
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message
                }
            };
        }
    };

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
    baseQuery: baseAxios({
        baseUrl: API_BASE_URL
    }),
    endpoints: () => ({})
});
