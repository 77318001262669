// assets
import {
    IconBriefcase,
    IconBuildingArch,
    IconBuildingCommunity,
    IconClipboardList,
    IconFileBarcode,
    IconListNumbers,
    IconSitemap,
    IconStackPop,
    IconUsers
} from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const setting = {
    id: 'setting',
    title: 'Setting',
    type: 'group',
    children: [
        {
            id: 'user',
            title: 'User',
            type: 'item',
            url: '/setting/user',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'draft-file-standard',
            title: 'Draft File Standard',
            type: 'item',
            url: '/setting/file-standard',
            icon: IconStackPop,
            breadcrumbs: false
        },
        {
            id: 'input-bulk',
            title: 'Input Data Excel',
            type: 'collapse',
            icon: IconClipboardList,
            breadcrumbs: false,
            children: [
                {
                    id: 'input-bulk-client-pic',
                    title: 'Input Data Client PIC',
                    type: 'item',
                    url: '/setting/input-bulk/client-pic',
                    breadcrumbs: false
                },
                {
                    id: 'input-bulk-agreement',
                    title: 'Input Data Agreement',
                    type: 'item',
                    url: '/setting/input-bulk/agreement',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default setting;
