// project imports
import MainLayout from 'layout/MainLayout';
import {
    UserPage,
    AddUserPage,
    EditUserPage,
    InputBulkPage,
    FileStandardPage,
    ExcelAgreementPage,
    ExcelDataPicPage
} from './Loadable/SettingRoutes';

// ==============================|| MAIN ROUTING ||============================== //
const SettingRoutes = {
    path: 'setting',
    element: <MainLayout />,
    children: [
        {
            path: 'user',
            children: [
                {
                    path: '',
                    element: <UserPage />
                },
                {
                    path: 'create',
                    element: <AddUserPage />
                },
                {
                    path: 'edit',
                    element: <EditUserPage />
                }
            ]
        },
        {
            path: 'input-bulk-xls',
            children: [
                {
                    path: '',
                    element: <InputBulkPage />
                }
            ]
        },
        {
            path: 'file-standard',
            children: [
                {
                    path: '',
                    element: <FileStandardPage />
                }
            ]
        },
        {
            path: 'input-bulk',
            children: [
                {
                    path: 'client-pic',
                    element: <ExcelDataPicPage />
                },
                {
                    path: 'agreement',
                    element: <ExcelAgreementPage />
                }
            ]
        }
    ]
};

export default SettingRoutes;
