import mem from 'mem';
// import { axios, responseInterceptor } from '../utils/axios';
import { axios } from '../utils/axios';
import Cookies from 'js-cookie';
import { getCookie, saveCookie } from './cookie';
import { setAccessToken } from '../utils/memoryToken';
import { AxiosHeaders, AxiosResponse } from 'axios';
import { encryptAES } from './dectenc';
import { SECRET_KEY } from 'configs/secretKey';
import { API_BASE_URL } from 'configs/apiUrl';

interface responseToken {
    memory: string;
    refresh: {
        refresh_token: string;
        expires_in: number;
    };
    user: {
        email: string;
        id: number;
        in_charge: string | string[];
        name: string;
        status: string;
        url_avatar: string | null;
    };
}

const refreshTokenFn = async () => {
    // axios.interceptors.response.eject(responseInterceptor);
    const user = getCookie('SOS-PERJANJIAN-USER');
    const refreshObj = getCookie('SOS-PERJANJIAN-SECURE');
    const $decipher = encryptAES(JSON.stringify(user), SECRET_KEY).toString();

    try {
        const response: AxiosResponse<responseToken> = await axios.post(
            API_BASE_URL + '/api/v1/auth/refresh',
            {
                ciphered: $decipher
            },
            {
                headers: {
                    refreshtoken: refreshObj.refresh_token
                }
            }
        );

        const { memory, refresh } = response.data;
        if (!memory) {
            Cookies.remove('SOS-PERJANJIAN-SECURE');
            Cookies.remove('SOS-PERJANJIAN-USER');
        }
        setAccessToken(memory);
        saveCookie('SOS-PERJANJIAN-SECURE', refresh, refresh.expires_in);

        return response;
    } catch (error: any) {
        Cookies.remove('SOS-PERJANJIAN-SECURE');
        Cookies.remove('SOS-PERJANJIAN-USER');
        return error;
    }
};

const maxAge = 10000;

const refreshTokenMemApi = mem(refreshTokenFn, {
    maxAge
});

export { refreshTokenMemApi };
