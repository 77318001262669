let accessToken = '';

export const setAccessToken = (s: string) => {
    accessToken = s;
};

export const getAccessToken = () => {
    return accessToken;
};

export const isAuth = () => {
    console.log('accessTokenMem =>', accessToken);
    if (accessToken) {
        return true;
    } else {
        return false;
    }
};
