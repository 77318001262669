// project imports
import MainLayout from 'layout/MainLayout';
import { AgreementLegalPage, AgreementInPage, ProcessInPage, DetailAgreementLegalPage } from './Loadable/LegalRoutes';

// ==============================|| MAIN ROUTING ||============================== //
const ProgressRoutes = {
    path: 'legal',
    element: <MainLayout />,
    children: [
        {
            path: 'agreement-progress-in',
            children: [
                {
                    path: '',
                    element: <AgreementInPage />
                },
                {
                    path: 'detail/:id',
                    element: <ProcessInPage />
                }
            ]
        },
        {
            path: 'agreement',
            children: [
                {
                    path: ':unitbispro',
                    element: <AgreementLegalPage />
                },
                {
                    path: ':unitbispro/detail/:id',
                    element: <DetailAgreementLegalPage />
                }
            ]
        }
    ]
};

export default ProgressRoutes;
