import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// login option 3 routing
const LoginPage = Loadable(lazy(() => import('modules/auth/pages/LoginPage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <LoginPage />
        }
    ]
};

export default AuthenticationRoutes;
