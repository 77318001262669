// ==============================|| REDUX - MAIN STORE ||============================== //

import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from 'lib/api/axios';
import { dashboardApi } from 'lib/api/dashboardApi';
import { loginApi } from 'lib/api/loginApi';

import customizationReducer from './customizationReducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: {
        customization: customizationReducer,
        [baseApi.reducerPath]: baseApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware)
});

type RootState = ReturnType<typeof store.getState>;

export { store, RootState };
