import Loadable from 'ui-component/Loadable';

import { lazy } from 'react';

export const ClientPicPage = Loadable(lazy(() => import('modules/client-pic/pages/ClientPicPage')));
export const AddClientPicPage = Loadable(lazy(() => import('modules/client-pic/pages/AddClientPicPage')));
export const EditClientPicPage = Loadable(lazy(() => import('modules/client-pic/pages/EditClientPicPage')));

export const AgreementPage = Loadable(lazy(() => import('modules/agreement/pages/AgreementPage')));
export const DetailAgreementPage = Loadable(lazy(() => import('modules/agreement/pages/DetailAgreementPage')));
export const AddAgreementPage = Loadable(lazy(() => import('modules/agreement/pages/AddAgreementPage')));
export const EditAgreementPage = Loadable(lazy(() => import('modules/agreement/pages/EditAgreementPage')));

export const ProjectExistingPage = Loadable(lazy(() => import('modules/project-existing/pages/ProjectExistingPage')));
export const AddProjectExistingPage = Loadable(lazy(() => import('modules/project-existing/pages/AddProjectExistingPage')));
export const EditProjectExistingPage = Loadable(lazy(() => import('modules/project-existing/pages/EditProjectExistingPage')));

export const AgreementProgressOutPage = Loadable(lazy(() => import('modules/agreement-progress/sales-out/pages/AgreementOutPage')));
export const ProcessOutPage = Loadable(lazy(() => import('modules/agreement-progress/sales-out/pages/ProcessOutPage')));
