import cryptoJs from 'crypto-js';

interface IjsonObj {
    ct: string;
    iv?: string;
    s?: string;
}

interface IcipherParams {
    ciphertext: cryptoJs.lib.WordArray;
    iv?: cryptoJs.lib.WordArray;
    salt?: cryptoJs.lib.WordArray;
}

var JsonFormatter = {
    stringify: function (cipherParams: IcipherParams) {
        // create json object with ciphertext
        var jsonObj: IjsonObj = { ct: cipherParams.ciphertext.toString(cryptoJs.enc.Base64) };
        // optionally add iv or salt
        if (cipherParams.iv) {
            jsonObj.iv = cipherParams.iv.toString();
        }
        if (cipherParams.salt) {
            jsonObj.s = cipherParams.salt.toString();
        }
        // stringify json object
        return JSON.stringify(jsonObj).replace(/\s/g, '');
    },
    parse: function (jsonStr: string) {
        // parse json string
        var jsonObj = JSON.parse(jsonStr);
        // extract ciphertext from json object, and create cipher params object
        var cipherParams = cryptoJs.lib.CipherParams.create({
            ciphertext: cryptoJs.enc.Base64.parse(jsonObj.ct)
        });
        // optionally extract iv or salt
        if (jsonObj.iv) {
            cipherParams.iv = cryptoJs.enc.Hex.parse(jsonObj.iv);
        }
        if (jsonObj.s) {
            cipherParams.salt = cryptoJs.enc.Hex.parse(jsonObj.s);
        }
        return cipherParams;
    }
};

export const encryptAES = (value: string | object, secret: string) => {
    const $decipher = cryptoJs.AES.encrypt(JSON.stringify(value), secret, {
        format: JsonFormatter
    }).toString();
    return $decipher;
};

export const decryptAES = (value: string | cryptoJs.lib.CipherParams, secret: string) => {
    const $decipher = JSON.parse(
        CryptoJS.AES.decrypt(value, secret, {
            format: JsonFormatter
        }).toString(CryptoJS.enc.Utf8)
    );
    return $decipher;
};
