// project imports
import MainLayout from 'layout/MainLayout';
import { DashboardPage } from './Loadable/AdminRoutes';

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardPage />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardPage />
                }
            ]
        }
    ]
};

export default AdminRoutes;
