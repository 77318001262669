// assets
import { IconBriefcase, IconBuildingArch, IconClipboardList, IconSitemap } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Sales = {
    id: 'sales',
    title: 'Sales',
    type: 'group',
    children: [
        {
            id: 'client-pic',
            title: 'Data Client PIC',
            type: 'item',
            url: '/sales/client-pic',
            icon: IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'project-existing',
            title: 'Project Existing',
            type: 'item',
            url: '/sales/project-existing',
            icon: IconClipboardList,
            breadcrumbs: false
        },
        {
            id: 'agreement',
            title: 'Agreement',
            type: 'collapse',
            icon: IconBuildingArch,
            breadcrumbs: false,
            children: [
                {
                    id: 'agreement-metro',
                    title: 'Metro',
                    type: 'item',
                    url: '/sales/agreement/metro',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-regional',
                    title: 'Regional',
                    type: 'item',
                    url: '/sales/agreement/regional',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-kam1',
                    title: 'KAM 1',
                    type: 'item',
                    url: '/sales/agreement/kam1',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-kam2',
                    title: 'KAM 2',
                    type: 'item',
                    url: '/sales/agreement/kam2',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-kam3',
                    title: 'KAM 3',
                    type: 'item',
                    url: '/sales/agreement/kam3',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-mpi',
                    title: 'MPI',
                    type: 'item',
                    url: '/sales/agreement/mpi',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-vendor-ga',
                    title: 'Vendor GA',
                    type: 'item',
                    url: '/sales/agreement/vendorga',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-vendor-project',
                    title: 'Vendor Project',
                    type: 'item',
                    url: '/sales/agreement/vendorproject',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'progress-out',
            title: 'Progress Out',
            type: 'item',
            url: '/sales/agreement-progress-out',
            icon: IconSitemap,
            breadcrumbs: false
        }
    ]
};

export default Sales;
