// assets
import { IconReport, IconListNumbers, IconSitemap, IconStackPop } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Legal = {
    id: 'legal',
    title: 'Legal',
    type: 'group',
    children: [
        {
            id: 'agreement-legal',
            title: 'Agreement',
            type: 'collapse',
            icon: IconReport,
            breadcrumbs: false,
            children: [
                {
                    id: 'agreement-sos',
                    title: 'SOS',
                    type: 'item',
                    url: '/legal/agreement/sos',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-hrp',
                    title: 'HRP',
                    type: 'item',
                    url: '/legal/agreement/hrp',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-tsl',
                    title: 'TSL',
                    type: 'item',
                    url: '/legal/agreement/tsl',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-mpi',
                    title: 'MPI',
                    type: 'item',
                    url: '/legal/agreement/mpi',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-sss',
                    title: 'SSS',
                    type: 'item',
                    url: '/legal/agreement/sss',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-prohr',
                    title: 'Pro HR',
                    type: 'item',
                    url: '/legal/agreement/prohr',
                    breadcrumbs: false
                },
                {
                    id: 'agreement-vendor',
                    title: 'Vendor',
                    type: 'item',
                    url: '/legal/agreement/vendor',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'progress-in',
            title: 'Progress In',
            type: 'item',
            url: '/legal/agreement-progress-in',
            icon: IconListNumbers,
            breadcrumbs: false
        }
    ]
};

export default Legal;
